import React, { Suspense, lazy } from 'react'
import './App.css'
import Header from './components/layout/header/Header'

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import Loader from './components/Loader'
import { WHATSAPP_ACCOUNT_NAME, WHATSAPP_NUMBER } from './config/appConfig'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from './assets/logo512.png'

const HomeRoutes = lazy(() => import('./router/HomeRoutes'))
const OurPlansRouts = lazy(() => import('./router/OurPlansRouts'))
const OurRecipes = lazy(() => import('./pages/ourRecipes/OurRecipes'))
const AboutuUs = lazy(() => import('./pages/aboutus/AboutuUs'))
const SignUp = lazy(() => import('./pages/signup/Signup'))
const FullRecipe = lazy(() => import('./features/recipes/FullRecipe'))
const PortalRouter = lazy(() => import('./router/PortalRoutes'))
const ContactUs = lazy(() => import('./pages/contactUs/ContactUs'))
const FullRecipeNewWindows = lazy(
  () => import('./features/recipes/FullRecipeNewWindows')
)
const ForgotPassword = lazy(() => import('./pages/login/ForgotPassword'))
const SuccessPage = lazy(() => import('./pages/PaymentPages/SuccessPage'))
const IndicatorPage = lazy(() => import('./pages/PaymentPages/IndicatorPage'))
const ErrorPage = lazy(() => import('./pages/PaymentPages/ErrorPage'))
const HowItsWorkRouts = lazy(() => import('./router/HowItsWorkRouts'))

function App() {
  return (
    <Router>
      <ScrollToTop />
      <FloatingWhatsAppWrapper />
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        <Routes>
          <Route path="/*" element={<HomeRoutes />} />
          <Route path="/ourPlans/*" element={<OurPlansRouts />} />
          <Route path="/howitswork" element={<HowItsWorkRouts />} />
          <Route path="/ourRecipes" element={<OurRecipes />} />
          <Route path="/aboutus" element={<AboutuUs />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/fullrecipe/:id" element={<FullRecipe />} />
          <Route path="/userportal/*" element={<PortalRouter />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/recipe/:index" element={<FullRecipeNewWindows />} />
          <Route path="/reset-password/:id" element={<ForgotPassword />} />
          <Route path="/pay/success" element={<SuccessPage />} />
          <Route path="/pay/indicator" element={<IndicatorPage />} />
          <Route path="/pay/error" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

// קומפוננטת עזר שמנהלת את הצגת ה-FloatingWhatsApp
function FloatingWhatsAppWrapper() {
  const location = useLocation()

  return (
    // אם הנתיב מכיל /userportal, ה-WhatsApp לא יוצג
    !location.pathname.startsWith('/userportal/selectionrecipes') &&
    !location.pathname.startsWith('/ourPlans/chooseMeal') && (
      <FloatingWhatsApp
        phoneNumber={WHATSAPP_NUMBER}
        accountName={WHATSAPP_ACCOUNT_NAME}
        avatar={logo}
        chatMessage="שלום, איך אני יכול לעזור?"
        placeholder="הקלד הודעה..."
        style={{ left: '20px', bottom: '20px' }} // מיקום בצד השמאלי למטה
      />
    )
  )
}

export default App
